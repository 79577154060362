/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "eoc-client-hosting-bucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://eoc-client-hosting-bucket-dev.s3-website-us-east-1.amazonaws.com",
    "aws_cognito_identity_pool_id": "us-east-1:8436ffdd-d086-4780-82ac-4ba8a9222226",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_MXsk1BKKG",
    "aws_user_pools_web_client_id": "607ndqhd18a7ln1tc9oai4r1ma",
    "oauth": {},
    "aws_user_files_s3_bucket": "eoc-client-storage04423-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
