import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Router, CanLoad, Route } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Observable, from, Subject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  public static targetUrlKey = 'targetUrl';
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService
      .getSession()
      .then(() => {
        return true;
      })
      .catch(() => {
        this.router.navigate(['user/login']);
        sessionStorage.setItem(AuthGuard.targetUrlKey, state.url);
        return false;
      });
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (!this.authService.isAuthenticated) {
      this.router.navigate(['']);
      return false;
    }
    if (route.data.menuFlag != null) {
      if ((route.data.menuFlag & this.authService.getSignInUser().menuFlags) > 0) {
        return true;
      } else {
        this.router.navigate(['']);
        return false;
      }
    }
    return true;
  }

  canLoad(route: Route): Observable<boolean> | boolean {
    return this.authService.isAuthenticated;
  }
}
