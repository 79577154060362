export interface Role {
    id:             number
    name:           string
    authority:      RoleAuthority
    isSystemRole:   boolean
}

export enum RoleFunctionsName {
    activate = "Activation",
    finance = "Finance",
    checkIn = "CheckIn",
    timeTable = "Ops Rhythm",
    resource = "Resource",
    incident = "Situational A.",
    document = "Document",
    activity = "Activity",
    admin = "Administration",
    task  = "Task",

    task_approve= "Approve Task",
}


export enum ViewAuthority {
    None = 0,
    View = 1,
    Edit = 3,
}
export interface RoleAuthority {
    activate: ViewAuthority
    timeTable: ViewAuthority
    finance: ViewAuthority
    checkIn: ViewAuthority
    resource: ViewAuthority
    incident: ViewAuthority
    document: ViewAuthority
    activity: ViewAuthority
    admin: ViewAuthority
    task : ViewAuthority

    task_approve: boolean
  }