// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  loginMsg:
    "Welcome to New EOC.<br> This is sandbox (aka DEV) environment of the new EOC.<br> Please sign-in with your official LAUSD single-sign-on (SSO) user ID and password. <br> As it's a development environment,<br> you will be able to see the latest changes <br> but be advised that the system may be sluggish for afew minutes at times <br> while we deploy such changes.",
  browserTitle: 'Kokomo 24/7',
  sidebarTitle: 'EMS',
  sidebarDesc: 'EOC Management System',
  sidebarLogoUrl: 'assets/img/logos/logo.png',
  sidebarBackgroundColor: 'black',
  loginPageLogoUrl: 'assets/img/logos/kokomo-logo.png',
  // AWS
  region: 'us-east-1',

  ClientId: '4t3i1henajnaic1feoi5je6a0h',
  AppWebDomain: 'ems-dev-new.auth.us-east-1.amazoncognito.com',
  TokenScopesArray: ['openid', 'email'],
  RedirectUriSignIn: 'https://dev.eoc247.com/login',
  UserPoolId: 'us-east-1_P5vkOMQ7q',
  RedirectUriSignOut: 'https://dev.eoc247.com/login',
  AdvancedSecurityDataCollectionFlag: false,

  s3_region: 'us-east-1',
  s3_bucket: 'attachment-files-ems-dev',
  s3_public_region: 'us-west-2',
  s3_public_bucket: 'attachment-files-public-ems',
  s3_public_top_folder: 'ems-dev',
  accessKeyId: 'AKIAWOHULLFEBHDGCUXI',
  secretAccessKey: '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',

  serverUrl: 'https://y4krifjkp7.execute-api.us-east-1.amazonaws.com/Prod/api/',
  // serverUrl: 'http://localhost:50441/api/',

  apiUrl: 'https://api.coloredstrategies.com',
  defaultMenuType: 'menu-default',
  subHiddenBreakpoint: 1440,
  menuHiddenBreakpoint: 768,
  themeColorStorageKey: 'vien-themecolor',
  isMultiColorActive: true,
  /*
  Color Options:
  'light.blueyale', 'light.blueolympic', 'light.bluenavy', 'light.greenmoss', 'light.greenlime', 'light.yellowgranola', 'light.greysteel', 'light.orangecarrot', 'light.redruby', 'light.purplemonster'
  'dark.blueyale', 'dark.blueolympic', 'dark.bluenavy', 'dark.greenmoss', 'dark.greenlime', 'dark.yellowgranola', 'dark.greysteel', 'dark.orangecarrot', 'dark.redruby', 'dark.purplemonster'
  */
  defaultColor: 'light.blueyale',
  isDarkSwitchActive: true,
  defaultDirection: 'ltr',
  themeRadiusStorageKey: 'vien-themeradius',
  isAuthGuardActive: true,
  firebase: {
    apiKey: 'AIzaSyCqoNLB_jTw4nncO12qR-eDH9gAeWiZVaw',
    authDomain: 'vien-angular-login.firebaseapp.com',
    databaseURL: 'https://vien-angular-login.firebaseio.com',
    projectId: 'vien-angular-login',
    storageBucket: 'vien-angular-login.appspot.com',
    messagingSenderId: '16217062888',
    appId: '1:16217062888:web:6b08232ca0c9662fedb85d',
    measurementId: 'G-8ETT79WRRN'
  }
};
