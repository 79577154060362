import { User } from './user';
import { Site } from 'app/models/site';
import { IncidentStatus } from 'app/models/incident';

export class GeneralDocument<T> {
  id: number;
  item: T;

  constructor(id: number, item: T) {
    this.id = id;
    this.item = item;
  }
}

export interface GeneralNameValue {
  name: string;
  value: any;
}

export interface GeneralReference {
  id: number;
  name: string;
}

export interface Attachment {
  id: number;
  path: string;
  name: string;
  size: number;
  created: Date | string;
  createdBy: number;
  createdByNavigation: User;
  contentType: string;
}

export interface Location {
  siteId: number;
  locationType: number;
  otherLocation: string;
  exactLocation: string;
  longitude: number;
  latitude: number;
  mapData: any;

  site: Site;
}

export enum LocationType {
  'On Campus' = 1,
  'Off Campus' = 1 << 1,
  'At Another School' = 1 << 2,
  'District Office' = 1 << 3,
  'District School Bus' = 1 << 4,
  'Trip To From School' = 1 << 5,
  'Trip To From School Sponsored Activity' = 1 << 6,
  //"Athletic" = 1 << 7,
  //"Cafeteria" = 1 << 8,
  //"Lunch Period" = 1 << 9,
  'Other' = 1 << 10
}

export interface Description {
  occurred: Date | string;
  description: string;
}

export interface CommonTree {
  id: number;
  name: string;
  className: string;

  children: CommonTree[];
  parentId: number;
  parent: CommonTree;
}

export interface BaseResult<T> {
  resultCode: ResultCodeType;
  detail: T;
  list: ListViewModel<T>;
  data: any;
}

export interface ListViewModel<T> {
  datas: T[];
  totalCount: number;
  pageIndex: number;
  pageSize: number;
}

export interface QueryKeyValue {
  key: string;
  value: any;
}

export interface SearchRequest {
  pageIndex: number;
  pageSize: number;
  searchKeyPairs: QueryKeyValue[];
}

export enum ResultCodeType {
  Error = -0x100,
  Success = 0,
  InvalidParameter,
  DbDuplicate,
  DbNotExist,
  DbForeignKey,
  DbError,
  NotSupported,
  NotImplemented,
  WrongState,

  EocError = 0x200,
  NotActivated,
  ClosedActvation,

  TaskError = 0x300,
  TaskCannotApprove,

  AuthError = 0x500,
  EmailPasswrodMismatch,
  AccountStatusSuspend,
  AccountStatusInactive
}

export enum ShiftType {
  A,
  B,
  C
}

export interface DatePickerResult {
  year: number;
  month: number;
  day: number;
}

export enum AttachmentType {
  help
}

export enum MapPinType {
  Site,
  Incident,
  Resource
}

export interface MapFilterResource {
  resourceId: number;
  inUse: number;
  capacity: number;
  rate: number;
}

export interface MapFilterIncident {
  issueTypes: number[];
  status: IncidentStatus;
  mapData: any;
}
/*
FilterData
   Incident => number[] : array of issue type id
   Resource => object : { resourceId: number, inUse: number, capacity: number, rate: number }
   Site => null
*/
export interface MapPin {
  type: MapPinType;
  id: number;
  longitude: number;
  latitude: number;
  filterData: MapFilterResource | MapFilterIncident;
}

export interface GroupByResult {
  key: any;
  count: number;
}
