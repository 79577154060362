import { Injectable, EventEmitter } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { User } from 'app/models/user';
import { Activation } from 'app/models/activation';
import { ActivationRoster, ActivationOrg } from 'app/models/activation-org';
import { CheckInHistory } from 'app/models/checkin-history';
import { Role, ViewAuthority } from 'app/models/role';
import { BaseResult, SearchRequest, ResultCodeType } from 'app/models/GeneralDocument';
import { environment } from 'environments/environment';
import { CognitoUser, CognitoUserSession, CognitoRefreshToken } from 'amazon-cognito-identity-js';
import { Hub, ICredentials } from '@aws-amplify/core';
import { Auth } from 'aws-amplify';

export interface ISignInCredentials {
  email: string;
  password: string;
}

export interface ICreateCredentials {
  email: string;
  password: string;
  displayName: string;
}

export interface IPasswordReset {
  code: string;
  newPassword: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  _signedUser: UserClass;
  cognitoUser: CognitoUser;
  session: CognitoUserSession; // Include IdToken / AccessToken / RefreshToken

  private _authState: BehaviorSubject<CognitoUser | any> = new BehaviorSubject<CognitoUser | any>(undefined);
  authState: Observable<CognitoUser | any> = this._authState.asObservable();

  public activationChanged: EventEmitter<null> = new EventEmitter<null>();
  public checkinChanged: EventEmitter<null> = new EventEmitter<null>();

  constructor(protected http: HttpClient) {
    Hub.listen('auth', data => {
      console.log('Hub.listen ======>!!!!!', data);
      const { channel, payload } = data;
      if (channel === 'auth') {
        this._authState.next(payload.event);
        if (payload.event == 'signIn' || payload.event == 'cognitoHostedUI') {
          if (payload.data) {
            this.cognitoUser = payload.data;
          }
        }
      }
    });
  }


  signedIn(session) {
    this.session = session;
    let resultCode = this.getResultCodeInJwtTokenPayload();
    if (resultCode == ResultCodeType.Success) {
      return true;
    } else {
      let errorMsg = this.getErrorMsgFromResultCodeInJwtTokenPayload(resultCode);
      // SweetAlert.default(errorMsg, "Please contact support@kokomo247.com", "warning").then(()=> {
      //     this.signOut();
      // });
      return false;
    }
  }

  getResultCodeInJwtTokenPayload() {
    let payloadData = this.session.getIdToken().decodePayload();
    let resultCode = payloadData['resultCode'];
    return resultCode == null ? null : parseInt(resultCode);
  }

  getErrorMsgFromResultCodeInJwtTokenPayload(resultCode: number) {
    let errorMsg = 'An unknown error has occurred.\nIf the problem persists, contact your administrator.';
    if (resultCode == null) {
      errorMsg = 'An error occurred communicating with the server';
    } else if (resultCode == ResultCodeType.EmailPasswrodMismatch) {
      errorMsg = 'The email address or password is incorrect.';
    } else if (resultCode == ResultCodeType.NotAssignCostcenter) {
      errorMsg = 'Your SSO ID is not yet configured with a default Cost Center in the system.';
    } else if (resultCode == ResultCodeType.AccountStatusInactive) {
      errorMsg = 'Your account status is inactive. Contact your administrator.';
    } else if (resultCode == ResultCodeType.AccountStatusSuspend) {
      errorMsg = 'Your account status is suspended. Contact your administrator.';
    } else if (resultCode == ResultCodeType.AuthError) {
      errorMsg = 'User does not exist';
    } else {
      errorMsg = errorMsg;
    }
    return errorMsg;
  }

  getSigninUserInfo(): Promise<ResultCodeType> {
    return new Promise(resolve => {
      return this.http.post<BaseResult<SessionInfo>>(environment.serverUrl + 'auth/signinUserInfo', null, { observe: 'response' }).subscribe(
        response => {
          if (response.body.resultCode == 0 && response.body.detail != null) {
            this._signedUser = new UserClass(response.body.detail);
            if (response.body.data && response.body.data.activationId) {
              this._signedUser.activationId = 1; // TODO response.body.data.activationId
            }
          }
          resolve(response.body.resultCode);
        },
        error => {
          resolve(ResultCodeType.Error);
        }
      );
    });
  }

  signOut() {
    return Auth.signOut({ global: true })
      .then(data => {
        this._signedUser = null;
        this.cognitoUser = null;
        console.log(data);
        return true;
      })
      .catch(err => {
        console.log(err);
        return false;
      });
  }

  register(credentials: ICreateCredentials) {
    return null
    // return from(
    //   this.afAuth.auth.createUserWithEmailAndPassword(credentials.email, credentials.password).then(() => {
    //     this.afAuth.auth.currentUser.updateProfile({ displayName: credentials.displayName });
    //     this.afAuth.auth.updateCurrentUser(this.afAuth.auth.currentUser);
    //   })
    // );
  }

  sendPasswordEmail(email) {
    return null
    // return from(this.afAuth.auth.sendPasswordResetEmail(email));
  }

  resetPassword(credentials: IPasswordReset) {
    return null
    // return from(this.afAuth.auth.confirmPasswordReset(credentials.code, credentials.newPassword));
  }

  getSession() {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(cognitoUserSession => {
          console.log(cognitoUserSession);
          this.session = cognitoUserSession;
          resolve(this.accessToken);
        })
        .catch(err => {
          console.log(err);
          reject(false);
        });
    });
  }

  get accessToken() {
    return this.session && this.session.getAccessToken().getJwtToken();
  }

  get idToken() {
    if (this.session == null) {
      return null;
    } else {
      return this.session && this.session.getIdToken().getJwtToken();
    }
  }

  get isAuthenticated() {
    const isAuth: boolean = this.session && this.session.isValid() ? true : false;
    if (!isAuth) {
      return isAuth;
    } else {
      const result = this.signedIn(this.session);
      return result;
    }
  }

  getSignInUser(): UserClass {
    return this._signedUser;
  }

  changeActivation(activation: Activation) {
    this._signedUser.changeActivation(activation);
    this.activationChanged.emit();
  }

  checkIn(checkInHistory: CheckInHistory) {
    this._signedUser.checkIn(checkInHistory)
    this.checkinChanged.emit()
  }

  checkOut() {
    this._signedUser.checkOut()
    this.checkinChanged.emit()
  }
}

export interface SessionInfo {
  user: User;

  activationId: number;
  activation: Activation;
  activationOrg: ActivationOrg;
  activationRoster: ActivationRoster;
  checkInHistory: CheckInHistory;

  recentActivation: Activation;
}

export class UserClass {
  name: string;
  role: Role;
  menuFlags: number;

  user: User;
  activationId: number;
  activation: Activation;
  activationOrg: ActivationOrg;
  activationRoster: ActivationRoster;
  checkInHistory: CheckInHistory;
  recentActivation: Activation;

  selectedActivationId: number;
  selectedActivation: Activation;

  startRoute: string;
  // routes: RouteInfo[]

  // private mergeMenuAcessFlag(viewAuthority:ViewAuthority, menuMask: MenuAccessFlag){
  //   if( viewAuthority > ViewAuthority.None ){
  //     this.menuFlags |= menuMask;
  //   }
  // }

  // private setMenuFlag(){
  //   let authority = this.role.authority;
  //   this.menuFlags = 0
  //   this.mergeMenuAcessFlag(authority.activate, MenuAccessFlag.Activation)
  //   this.mergeMenuAcessFlag(authority.admin, MenuAccessFlag.Admin)

  //   if( this.selectedActivationId > 0 ){
  //     this.mergeMenuAcessFlag(authority.activity, MenuAccessFlag.Activity)
  //     this.mergeMenuAcessFlag(authority.finance, MenuAccessFlag.Finance)
  //     this.mergeMenuAcessFlag(authority.timeTable, MenuAccessFlag.TimeTable)
  //     this.mergeMenuAcessFlag(authority.checkIn, MenuAccessFlag.CheckIn)
  //     this.mergeMenuAcessFlag(authority.resource, MenuAccessFlag.Resource)
  //     this.mergeMenuAcessFlag(authority.incident, MenuAccessFlag.Incident)
  //     this.mergeMenuAcessFlag(authority.document, MenuAccessFlag.Document)
  //     this.mergeMenuAcessFlag(authority.task, MenuAccessFlag.Task)
  //   }
  // }

  // private buildRoutes(){
  //   let routes: RouteInfo[] = []
  //   ROUTES.forEach(route=>{
  //     if( (route.flag & this.menuFlags) > 0 ){
  //       routes.push(route);
  //     }
  //   })
  //   this.routes = routes;
  //   if( this.selectedActivationId > 0 ){
  //     this.startRoute = 'operation/dashboard'
  //   }
  //   else{
  //     this.startRoute = this.role.authority.activate > ViewAuthority.None? 'activation' : 'operation/noactivation'
  //   }
  // }

  constructor(sessionInfo: SessionInfo) {
    this.user = sessionInfo.user;
    this.name = sessionInfo.user.firstName + ' ' + sessionInfo.user.lastName;
    this.role = sessionInfo.user.role;

    this.selectedActivationId = this.activationId = sessionInfo.activationId;
    this.selectedActivation = this.activation = sessionInfo.activation;
    this.activationOrg = sessionInfo.activationOrg;
    this.activationRoster = sessionInfo.activationRoster;
    this.checkInHistory = sessionInfo.checkInHistory;
    this.recentActivation = sessionInfo.recentActivation;

    this.readFromSesstionStorage();
    // this.setMenuFlag()
    // this.buildRoutes()
  }

  checkIn(checkInHistory: CheckInHistory) {
    this.checkInHistory = checkInHistory;
    this.activationRoster.onsite = true;
  }

  checkOut() {
    this.checkInHistory = null;
    this.activationRoster.onsite = false;
  }

  private readFromSesstionStorage() {
    let curAct = sessionStorage.getItem('curAct');
    if (curAct != null) {
      this.selectedActivation = JSON.parse(curAct) as Activation;
      this.selectedActivationId = this.selectedActivation.id;
    }
  }

  private setSessionStorage(activation: Activation) {
    sessionStorage.setItem('curAct', JSON.stringify(activation));
  }

  changeActivation(activation: Activation) {
    if (this.role.authority.activate == ViewAuthority.None) {
      // swal("You don't have authority")
      return;
    }
    this.setSessionStorage(activation);
    this.selectedActivationId = activation.id;
    this.selectedActivation = activation;
    // this.setMenuFlag()
    // this.buildRoutes()
  }
}
