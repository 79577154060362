export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
}

const data: IMenuItem[] = [
  {
    id: 'activations',
    icon: 'iconsminds-air-balloon-1',
    label: 'menu.eoc-activation',
    to: '/app/activation'
  },
  {
    id: 'dashboard',
    icon: 'iconsminds-monitor-analytics',
    label: 'menu.dashboard',
    to: '/app/operation/dashboard'
  },
  {
    id: 'operation',
    icon: 'iconsminds-edit-map',
    label: 'menu.operation',
    to: '/app/blank-page',
    subs: [
      {
        id: 'org-chart',
        icon: 'simple-icon-organization',
        label: 'menu.org-chart',
        to: '/app/operation/organization'
      },
      {
        id: 'incident',
        icon: 'simple-icon-map',
        label: 'menu.incident',
        to: '/app/operation/incident'
      },
      {
        id: 'timetable',
        icon: 'simple-icon-calendar',
        label: 'menu.timetable',
        to: '/app/operation/timetable'
      },
      {
        id: 'resources',
        icon: 'simple-icon-wallet',
        label: 'menu.resource',
        to: '/app/operation/resource'
      },
      {
        id: 'activities',
        icon: 'simple-icon-hourglass',
        label: 'menu.activity',
        to: '/app/operation/activity'
      },
      {
        id: 'tasks',
        icon: 'simple-icon-check',
        label: 'menu.task',
        to: '/app/operation/task'
      },
      {
        id: 'documents',
        icon: 'simple-icon-paper-clip',
        label: 'menu.document',
        to: '/app/operation/document/Documents'
      },
      {
        id: 'check-in-history',
        icon: 'simple-icon-clock',
        label: 'menu.checkin',
        to: '/app/operation/checkin'
      },
      {
        id: 'activation-noti',
        icon: 'iconsminds-loudspeaker',
        label: 'menu.activation-noti',
        to: '/app/operation/activation-noti'
      }
    ]
  },
  {
    id: 'admin',
    icon: 'simple-icon-settings',
    label: 'menu.admin',
    to: '/app/blank-page',
    subs: [
      {
        icon: 'simple-icon-people',
        label: 'menu.user',
        to: '/app/admin/user'
      },
      {
        icon: 'simple-icon-home',
        label: 'menu.site',
        to: '/app/admin/site'
      },
      {
        icon: 'iconsminds-business-mens',
        label: 'menu.group',
        to: '/app/admin/group'
      },
      {
        icon: 'simple-icon-note',
        label: 'menu.form',
        to: '/app/admin/form'
      },
      {
        label: 'menu.constants',
        to: '/app/blank-page',
        subs: [
          {
            icon: 'simple-icon-pin',
            label: 'menu.issue-type',
            to: '/app/admin/issue-type'
          },
          {
            icon: 'simple-icon-user-follow',
            label: 'menu.role',
            to: '/app/admin/role'
          },
          {
            icon: 'simple-icon-organization',
            label: 'menu.organization',
            to: '/app/admin/organization'
          },
          {
            icon: 'simple-icon-wallet',
            label: 'menu.resource',
            to: '/app/admin/resource'
          }
        ]
      },
      {
        label: 'menu.notification',
        to: '/app/blank-page',
        subs: [
          {
            icon: 'simple-icon-paper-plane',
            label: 'menu.activation',
            to: '/app/admin/notification/activation'
          },
          {
            icon: 'simple-icon-paper-plane',
            label: 'menu.task',
            to: '/app/admin/notification/task'
          }
        ]
      }
    ]
  },
  {
    id: 'logs',
    icon: 'iconsminds-library',
    label: 'menu.logs',
    to: '/app/blank-page',
    subs: [
      {
        icon: 'simple-icon-graph',
        label: 'menu.exception-logs',
        to: '/app/blank-page'
      },
      {
        icon: 'simple-icon-chart',
        label: 'menu.system-logs',
        to: '/app/blank-page'
      }
    ]
  }
];
export default data;
