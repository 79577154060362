import { Injectable } from "@angular/core";
import { HttpClient, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { AuthService, UserClass } from 'app/shared/auth.service';
import { Observable, BehaviorSubject, throwError, of } from "rxjs";
import { catchError, finalize, switchMap, filter, take, retryWhen, concatMap, delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
// import { LoadingService } from 'app/services/loading.service';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private requestCount: number = 0;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private authService: AuthService,
    protected http: HttpClient,
    private router: Router,
    // public loadingService: LoadingService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    console.log("HttpInterceptor -> intercept", request.url);
    if ((request.url.includes("Incidents") || request.url.includes("IncidentTasks") || request.url.includes("signinUserInfo") || request.url.includes("/Dashboards") || request.url.includes("SelfReportResults")) && !request.url.includes("relatedStudentsInfo") && !request.url.includes("heatMapData")){
      ++this.requestCount;
      // this.loadingService.show();
      console.log('request Count', this.requestCount);
    }
    if (!request.url.includes("oauth2/token")){
      request = this.setHeaders(request);
    }

    return next.handle(request)
        .pipe(
        catchError((error: Error) => {
          console.log("HttpInterceptor -> intercept -> catchError", request.url);
          if(error instanceof HttpErrorResponse){
            // We don't want to refresh token for some requests like login or refresh token itself
            // So we verify url and we throw an error if it's the case
            // if ( request.url.includes("refreshToken") || request.url.includes("login") || request.url.includes("signin") )
            // {
            //     // We do another check to see if refresh token failed
            //     // In this case we want to logout user and to redirect it to login page
            //     // api/refreshToken -> error == expire refresh Token
            //     if (request.url.includes("refreshToken")) {
            //       this.authService.signoutUser(false);
            //       this.router.navigate(['login']);
            //     }
            //     return throwError(error);
            // }
            // If error status is different than 401 we want to skip refresh token
            // So we check that and throw the error if it's the case
            if (error.status !== 401) {
              return throwError(error);
            }
            if (this.refreshTokenInProgress) {
              // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
              // – which means the new token is ready and we can retry the request again

              return this.refreshTokenSubject.pipe(
                filter(result => result !== null)
                ,take(1)
                ,switchMap(() => next.handle(this.addAuthenticationToken(request)))
              );
            } else {
              this.refreshTokenInProgress = true;

              // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
              this.refreshTokenSubject.next(null);

              // Call auth.refreshAccessToken(this is an Observable that will be returned)
              return this.refreshAccessToken().pipe(

                switchMap((token: any) => {
                  // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                  // for the next time the token needs to be refreshed
                  this.refreshTokenInProgress = false;
                  this.refreshTokenSubject.next(token);
                  return next.handle(this.addAuthenticationToken(request));
                })
                ,catchError((error: Error) => {
                  this.refreshTokenInProgress = false;
                  return throwError(error);
                })
              )
            }
          } else {
            return throwError(error);
          }
        }),
        retryWhen(errors => errors
          .pipe(
            concatMap((error, count) => {
              // https://docs.aws.amazon.com/apigateway/api-reference/handling-errors/
              if (count < 2 && (error.status == 502 || error.status == 503 || error.status == 504)) {
                return of(error.status);
              }

              return throwError(error);
            }),
            delay(500)
          )
        ),
        finalize(() => {
          console.log("HttpInterceptor -> intercept -> finalize", request.url);
          if( (request.url.includes("Incidents") || request.url.includes("IncidentTasks") || request.url.includes("signinUserInfo") || request.url.includes("/Dashboards") || request.url.includes("SelfReportResults")) && !request.url.includes("relatedStudentsInfo") && !request.url.includes("heatMapData")){
            --this.requestCount;
            if( this.requestCount == 0 ) {
              console.log('loading hide');
              // this.loadingService.hide();
            }
          }
        })
      );
  }

  private setHeaders(request: HttpRequest<any>): HttpRequest<any> {
    const token = this.authService.idToken;
    const contentType = request.headers.get('Content-Type') ? request.headers.get('Content-Type') : 'application/json';

    if (token) {
      request = request.clone({
        setHeaders: {
          'content-type': contentType,
            Authorization: `Bearer ${token}`
        }
      });
    }
    return request;
  }


  addAuthenticationToken(request) {
    // Get access token from Local Storage
    const accessToken = this.authService.accessToken;

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken) {
        return request;
    }

    // We clone the request, because the original request is immutable
    return this.setHeaders(request);
  }

  readonly serverUrl: string = environment.serverUrl;

  refreshAccessToken(): Observable<any> {
    return Observable.create(observer => {
      this.authService.getSession().then(result => {
        observer.next(result);
        observer.complete();
      }).catch(error => {
        throw observer.throw(error);
      })
    });
  }
}
