import { User } from "./user";

export class GeneralDocument<T>{
    id : string;
    item : T;

    constructor(id: string, item: T){
        this.id = id;
        this.item = item;
    }
}

export interface GeneralReference {
    id: string;
    name : string;
}

export class AWSGeneralDocument<T>{
    id : number;
    item : T;

    constructor(id: number, item: T){
        this.id = id;
        this.item = item;
    }
}

export interface AWSGeneralReference {
    id: number;
    name : string;
}

export interface Attachment {
    id: number;
    type: AttachmentType;
    downloadUrl : string;
    name : string;
    size : number;
    created: Date;
    createdBy: number;

    createdByNavigation: User;
}

export interface GeneralUpdatedInfo{
    id: number
    occured: Date | string
    description: string
    longitude: number
    latitude: number
    created: Date
    createdBy: number
    createdByNavigation: User
}

export interface BaseResult<T>{
    resultCode?: number
    detail?: T
    list: ListViewModel<T>
    data: any
}

export interface ListViewModel<T> {
    datas : T[]
    totalCount: number
    pageIndex: number
    pageSize: number
}

export interface QueryKeyValue {
    key: string
    value: any
}

export interface SearchRequest {
    pageIndex: number
    pageSize: number
    searchKeyPairs: QueryKeyValue[]
}

export enum ResultCodeType{
    Error= -0x100,
    Success = 0,
    InvalidParameter,
    DbDuplicate,
    DbNotExist,
    DbForeignKey,
    DbError,
    NotSupported,
    NotImplemented,
    NonPilotSchool,
    CognitoError,
    CognitoUserNameExists,
    CognitoUserNotFound,
    CognitoUnsupportedUserState,

    IncidentError = 0x100,
    WrongStateApproval,
    IncidentErrorStatusChanged,

    IncidentTaskError = 0x180,
    AssigneeNotAuthorized,

    EocError = 0x200,

    SelfReportError = 0x250,
    SubmitEndSurvey,

    OrganizationError = 0x300,
    OrganizationSiteDuplicate,
    OrganizationUserDuplicate,

    AuthError = 0x500,
    EmailPasswrodMismatch,
    AccountStatusSuspend,
    AccountStatusInactive,
    NotAssignCostcenter

}

export interface DatePickerResult {
    year : number
    month : number
    day : number
}

export enum AttachmentType {
    help
}

export interface MapPin {
    id: number
    name: string
    longitude: number
    latitude: number
    filterData: any
}
