import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { ViewsModule } from './views/views.module';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { LayoutContainersModule } from './containers/layout/layout.containers.module';
import { Auth } from 'aws-amplify';
import { AuthService } from 'app/shared/auth.service';
import { TokenInterceptorService } from 'app/shared/token-interceptor.service';
import { ResultCodeType } from 'app/models/common-models';
import { AuthGuard } from 'app/shared/auth-guard.service';
import { AgmCoreModule } from '@agm/core';

export function startupServiceFactory(authService: AuthService): Function {
  return () =>
    new Promise(async (resolve, reject) => {
      Auth.currentSession()
        .then(cognitoUserSession => {
          console.log(cognitoUserSession);
          authService.session = cognitoUserSession;
          authService.getSigninUserInfo().then(result => {
            resolve(true);
            // if (result == ResultCodeType.Success) {
            //   resolve(true);
            // } else {
            //   authService.signOut();
            //   resolve(true);
            // }
          });
        })
        .catch(err => {
          console.log(err);
          resolve(true);
        });
    });
}

@NgModule({
  imports: [
    BrowserModule,
    ViewsModule,
    AppRoutingModule,
    LayoutContainersModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCnDJTFvh627ZmrSuf4SRtxoZLx5ozTd54', // https://console.cloud.google.com/google/maps-apis/api-list?project=kokomo247
      libraries: ['places', 'weather', 'visualization', 'drawing', 'geometry']
    }),
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase)
  ],
  declarations: [AppComponent],
  providers: [
    AuthGuard,
    {
      // Provider for APP_INITIALIZER
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [AuthService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
